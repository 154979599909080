let config = {
    
    // baseUrl:"/api",
    baseUrl:"/screen",

    // baseUrl:"http://ahdcloud.com/screen",
    //baseUrl:"http://192.168.1.9:18882",
    //mqttUrl:"ws://192.168.1.9:8083/mqtt",
    mqttUrl:"ws://ahdcloud.com:20502/mqtt",

    //cameraUrl:"http://192.168.1.87:18885",
    cameraUrl:"http://47.100.124.36:31012",

    tcpUrl:"/tcp",
    
    // tcpUrl:"http://192.168.1.63:18886",
    testUrl:"http://192.168.1.87:18882"

}
export default config;
