/*
 * @Author: ChenLong
 * @Date: 2021-06-04 10:33:23
 * @LastEditTime: 2021-06-13 13:45:44
 * @LastEditors: ChenLong
 * @Description:
 */

import {
  createRouter,
  createWebHashHistory
} from "vue-router";
const Login = () => import("sudp-common/component/Login");
const portalIndex = () => import("@/views/index");

const constantRoutes = [{
    path: "/",
    component: Login,
    name: "login",
  },
  {
    path: "/main",
    component: portalIndex,
    name: "portalIndex",
  }
];

// https://www.npmjs.com/package/vue-router
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export default router;
