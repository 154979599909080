import axios from "axios";
import config from "../public/config";
import {
  ElMessage,
  ElNotification
} from "element-plus";

switch (process.env.NODE_ENV) {
  case "development":
    console.log("新版开发环境");
    break;
  case "production":
    console.log("新版生产环境");
    break;
  default:
    console.log("新版测试环境");
    break;
}

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8"; //配置请求头
//请求拦截器
axios.interceptors.request.use(
  (config) => {
    //const token = 'bfLlV6T488jrHOrDXViNWtURDPLOJfMsgoFqLRJXW1np4r9iQtlykouPHIqkSrW7';
    const token = localStorage.getItem('Tit-token');
    console.log(token)
    token && (config.headers["tit-token"] = token);
    return config;
  },
  (err) => {
    ElMessage.error("请求异常");
    console.log("请求大错误：", err);
  }
);
let status = true;
//响应拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.data.code === 0) {
      // oss接口-正确回调
      return res.data;
    } else if (res.data.code === 200) {
      // 业务接口-正确回调
      if (res.data.data === undefined) {
        // 获取设备树数据（deviceBaseInfo)
        return res.data;
      }
      return res.data.data;
    } else if (res.data.code === 401 && status) {
      ElNotification.error({
        title: 'token错误',
        message: "Status:401，请重新登录！"
      });
      window.location.href = location.origin + location.pathname;
      status = false;
      return false;
    } else if (res.data.code == 500) {
      return res;
    } else {
      // ElMessage.error(res.data.msg);
      return Promise.reject(res);
    }
  },
  (err) => {
    console.log(err.response.status);
    ElMessage.error("响应异常");
    console.log("响应大错误：", err.response);
    return Promise.reject();
  }
);

// 封装aixos调用方法
function request({
  type = "get",
  args
}) {
  return new Promise((resolve, rejuect) => {
    axios[type](...args)
      .then((data) => {
        // >>必须这样写，不能改成!data
        if (data !== false) {
          resolve(data);
        }
      })
      .catch((err) => {
        // rejuect();
        console.log(err);
      });
  });
}

function get(...args) {
  return request({
    type: "get",
    args
  });
}

function post(...args) {
  return request({
    type: "post",
    args
  });
}
// function put(...args) {
//     return request({ type: 'put', args })
// }
// function del(...args) {
//     return request({ type: 'delete', args })
// }
// 所有接口
const HTTP = config.baseUrl;
const websoketHTTP = 'ws://192.168.1.68:18882';
axios.API = {
  //==============================  登录界面 ==================================
  getLoginInfo: (data) => post(HTTP + "/login/getLoginInfo", data),
  getSsoAuthUrl: (data) => post(HTTP + "/login/getSsoAuthUrl", data),
  doLoginByTicket: (data) => post(HTTP + "/login/doLoginByTicket", data),
  logout: () => post(HTTP + "/sso/logout"),
  //==============================  WebSocket接口 ==================================
  // 智能路桩websoket接口
  itgsWebSocket: () => new WebSocket(websoketHTTP + '/websocket/itgs_' + localStorage.getItem('loginId')),
  // 气象精灵websoket接口
  miraWebSocket: () => new WebSocket(websoketHTTP + '/websocket/melves_' + localStorage.getItem('loginId')),
  // 桥梁/边坡websoket接口
  bhmsWebSocket: () => new WebSocket(websoketHTTP + '/websocket/bhms_' + localStorage.getItem('loginId')),
  // 平安花websoket接口
  pahWebSocket: () => new WebSocket(websoketHTTP + '/websocket/pah_' + localStorage.getItem('loginId')),
  //==============================  主界面 ==================================
  // 获取最新的数据
  mainInit: (data) => post(HTTP + "/main/init", data),
  initProjects: (data, code) => post(HTTP + "/main/" + code + "/initProjects", data),
  getStatusIndex: (data, code) => post(HTTP + "/main/" + code + "/getStatusIndex", data),
  authProductQuery: (data) => post(HTTP + "/auth/product/query", data),
  //==============================  气象精灵 ==================================
  // 根据项目编号获取对应的设备数据
  miraDeviceBaseInfo: (data) => post(HTTP + "/melves/deviceBaseInfo/query", data),
  // 根据设备编号获取对应的设备数据
  miraTransData: (data) => post(HTTP + "/melves/transData", data),
  // 告警已读处理
  miraCancelTips: (data) => post(HTTP + "/alarm/cancelTips",data),
  //==============================  平安花 ==================================
  // 根据项目编号获取对应的设备数据
  pahDeviceBaseInfo: (data) => post(HTTP + "/pah/deviceBaseInfo/query", data),
  // 根据设备编号获取对应的设备数据
  pahTransData: (devCode) => get(HTTP + "/pah/transData/" + devCode),
  // 路由控制
  controlRouter: (data) => post(HTTP + "/pah/exec/controlOrder/openRouter", data),
  // 工作模式控制
  controlMode: (data) => post(HTTP + "/pah/exec/controlOrder/workMode", data),
  // 投影控制
  controlProjection: (data) => post(HTTP + "/pah/exec/controlOrder/projection", data),
  // 雷达控制
  controlRadar: (data) => post(HTTP + "/pah/exec/controlOrder/radar", data),
  // 节目单控制
  controlSpeaker: (data) => post(HTTP + "/pah/exec/controlOrder/speaker", data),
  //==============================  智慧路桩 ==================================
  // 根据项目编号获取对应的设备数据
  // itgsDeviceBaseInfo: (data) => post(HTTP + "/itgs/exec/dev/getAllDevices", data),
  itgsDeviceBaseInfo: (data) => post(HTTP + "/itgs/deviceBaseInfo/query", data),
  // 根据设备编号获取对应的设备数据
  itgsTransData: (devCode) => get(HTTP + "/itgs/transData/" + devCode),
  //  设备控制接口
  itgsDevControl: (data) => post(HTTP + "/itgs/exec/control/sendMessageForShow", data),
  //  获取语音播报菜单
  getVoiceList: () => get(HTTP + "/itgs/exec/itgsVoice/getVoiceList"),
  //获取设备列表
  getDevices: () => get(HTTP + "/itgs/exec/devices/getAllDevices"),
  // 根据设备编号获取图片链接
  getNewPic: (devCode) => get(HTTP + "/itgs/exec/itgsDevFile/getNewPic/" + devCode),
  // 根据设备编号以及类型获取视频链接
  getVideoUrl: (data) => post(HTTP + "/itgs/exec/sysDict/getVideoUrl", data),
  //设备更新
  update: (data) => post(HTTP + "/itgs/exec/devices/insertOrUpdate", data),
  changeDevcode: (data) => post(HTTP + "/itgs/exec/program/changeDevcode" + data),
  // 1 视频推流设备
  getListByControl: (data) => get(HTTP + "/itgs/exec/devices/getByControl/" + data),
  setControlMessage: (data) => post(HTTP + "/itgs/exec/control/sendMessage", data),
  //==============================  桥梁监测/边坡监测 ==================================
  // 根据项目编号获取对应的设备数据
  bhmsDeviceBaseInfo: (data) => post(HTTP + "/bhms/deviceBaseInfo/query", data),
  //  老版编号获取对应的设备数据
  bhmsTransData: (devCode, data) => get(HTTP + "/bhms/transData/" + devCode, data),
  //  新版桥梁设备编号获取对应的设备数据
  newBhmsTransData: (devCode, data) => get(HTTP + "/bhmsV2/transData/" + devCode, data),
  //  根据设备编号获取全部模块信息
  bhmsModuleInfo: (data) => post(HTTP + "/bhms/exec/bhmsModuleInfo/query", data),
  //  获取网关数据
  getGateTableData: (data) => post(HTTP + "/bhms/bhmsDataGate/page", data),
  getGateGroup: (data) => post(HTTP + "/bhms/exec/bhmsDataGate/getGateGroup", data),
  //  获取振动数据
  getVibTableData: (data) => post(HTTP + "/bhms/bhmsDataVibrateDisplay/page", data),
  getVibrateGroup: (data) => post(HTTP + "/bhms/exec/bhmsDataVibrate/getVibrateGroup", data),
  //  获取风速风向仪数据
  getEnvTableData: (data) => post(HTTP + "/bhms/bhmsDataEnv/page", data),
  getEnvGroup: (data) => post(HTTP + "/bhms/exec/bhmsDataEnv/getEnvGroup", data),
  //  获取温湿度传感器数据
  getTempTableData: (data) => post(HTTP + "/bhms/bhmsDataEnv/page", data),
  getTempGroup: (data) => post(HTTP + "/bhms/exec/bhmsDataEnv/getTempGroup", data),
  //  获取中继数据
  getRlyTableData: (data) => post(HTTP + "/bhms/bhmsDataRelay/page", data),
  getRlyGroup: (data) => post(HTTP + "/bhms/exec/bhmsDataRelay/getRelayGroup", data),
  //获取告警数据
  deviceAlarm: (data) => post(HTTP + "/bhms/deviceAlarmInfo/page?pageSize=50&pageNum=1", data),
  //获取新版告警数据
  deviceAlarmNew: (data) => post(HTTP + "/bhms/bhmsAlarmData/page?pageSize=50&pageNum=1", data),
  //分页获取告警数据
  deviceAlarmPage: (pageSize, pageNum, data) => post(HTTP + "/bhms/deviceAlarmInfo/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
  //告警数据导出
  alarmExport: (data) => post(HTTP + "/bhms/exec/deviceAlarmInfo/export", data),
  //获取综合评估值
  healthByCode: (data) => post(HTTP + "/bhms/exec/bhmsBridgeHealth/healthByCode", data),
  //获取图片
  getBridgePic: (data) => post(HTTP + "/bhms/exec/bhmsBridgePic/list", data),
  //获取视频
  getBridgeVideo: (data) => post(HTTP + "/bhms/exec/bhmsVideoHsl/list", data),
  //获取边坡天气
  getEnvByDevCode: (data) => post(HTTP + "/bhms/exec/bhmsDataEnv/getEnvByDevCode", data),
  //气象数据导出
  envExport: (data) => post(HTTP + "/bhms/exec/bhmsDataEnv/export", data),
  //  获取界面中的水位数据
  getMapAllData: (data) => get(HTTP + "/wlms/" + data),
  //  获取最新抓拍照片
  getCameraFilPath:(pageSize, pageNum, data)=>post(HTTP + "/camera/filePath/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //  获取气象信息
    bhmsWeatherGetDevStatus: (data) => post(HTTP + "/melves/exec/deviceBaseInfo/getDevStatus", data),

    getProjectCode:(data) => post(HTTP + "/auth/project/query", data),

    //周期数据
    bhmsCycleData: (pageSize, pageNum, data) => post(HTTP + "/bhms/bhmsCycleData/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //周期数据导出
    bhmsCycleDataExport: (data) => post(HTTP + "/bhms/exec/bhmsCycleData/export", data),
    //周期预留数据
    bhmsCycleReserveData: (pageSize, pageNum, data) => post(HTTP + "/bhms/bhmsCycleReserveData/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //周期预留导出
    bhmsCycleReserveDataExport: (data) => post(HTTP + "/bhms/exec/bhmsCycleReserveData/export", data),
    //周期振动数据
    bhmsCycleVibrateData: (pageSize, pageNum, data) => post(HTTP + "/bhms/bhmsCycleVibrateData/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //周期振动导出
    bhmsCycleVibrateDataExport: (data) => post(HTTP + "/bhms/exec/bhmsCycleVibrateData/export", data),
    //天气数据
    bhmsM2WeatherData: (pageSize, pageNum, data) => post(HTTP + "/melves/status.M2/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //天气数据导出
    bhmsM2WeatherDataExport: (data) => post(HTTP + "/melves/exec/status.M2/export", data),
    //水位数据
    bhmsWlmsStatusData: (pageSize, pageNum, data) => post(HTTP + "/wlms/wlmsStatus/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
    //水位数据导出
    bhmsWlmsStatusDataExport: (data) => post(HTTP + "/wlms/exec/wlmsStatus/export", data),
    //==============================  隧道 =================
  // 设备实时数据列表
  devList: (id) => get("/api/reDataMonitorDevice/listByTunnelCode/" + id),
  // 新增运动控制
  controlSport: (param) => post("/api/reControlDevSport", param),
  // 新增状态灯控制
  controlLight: (param) => post("/api/reControlDevLight", param),
  // 新增外设控制
  controlPerip: (param) => post("/api/reControlDevPerip", param),
  // 获取最新设备上下线数据
  newDevParamData: (param) =>
    post("/api/reControlDevParam/getNewDevParamData", param),
  // 新增参数单条数据
  devParam: (param) => post("/api/reControlDevParam", param),
  // 获取相机
  tunnelCamera: (param) => post("/api/reCamera/list", param),
  // 控制球机
  tunnelCameraControl: (param) => post("/api/reCamera/ptz", param),
  // 查询图表
  getEcharts: (data) => post("/api/tunnelAirData/charts", data),
  //==============================  雄安气象精灵 =================
  // 设备编号以及项目名称查询图表数据
  melvesGetDevicesInfo: (data) => post(HTTP + "/melves/exec/roadStatus/getDevicesInfo", data),
  melvesDeviceStatus: (pageSize, pageNum, data) => post(HTTP + '/melves/deviceStatus/page?pageSize=' + pageSize + '&pageNum=' + pageNum, data),
  melvesRoadStatus: (pageSize, pageNum, data) => post(HTTP + '/melves/roadStatus/page?pageSize=' + pageSize + '&pageNum=' + pageNum, data),
  melvesInit: (data) => post(HTTP + "/melves_pro/init", data), // 项目初始化数据
  //============================== 通用下载导出方法 ==================================================================
  download: (fileName, projectCode) => {
    window.location.href = HTTP + "/" + projectCode + "/common/download?fileName=" + encodeURI(fileName);
  },

  //==============================  水位精灵界面 ==================================
  //  获取设备列表
  wlmsDevList: (data) => post(HTTP + '/wlms/deviceBaseInfo/query', data),
  // 获取地图界面所有数据
  getMapAllData: (data) => get(HTTP + "/wlms/" + data),
  // 获取水位变化趋势
  wlmsChartsData: (data) => post(HTTP + '/wlms/exec/wlmsStatus/getDevicesInfo', data),
  // 告警分页
  alarmPage: (pageSize, pageNum, data) => post(HTTP + `/wlms/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`, data),
  // 解除告警
  wlmsAlarmCancel: (data) => post(HTTP + `/wlms/exec/deviceAlarmInfo/cancel`, data),
  // 数据分页
  wlmsStatus: (pageSize, pageNum, data) => post(HTTP + `/wlms/wlmsStatus/page?pageNum=${pageNum}&pageSize=${pageSize}`, data),
  // 设备状态导出
  wlmsStatusExport: (data) => post(HTTP + '/wlms/exec/wlmsStatus/export', data),
  // 设备上下线查询
  wlmsMonitorOnline: (pageSize, pageNum, data) => post(HTTP + "/wlms/devStatusTrace/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data),
  // 设备上下线导出
  wlmsMonitorOnlineExport: (data) => post(HTTP + "wlms/exec/devStatusTrace/export", data),

  //==============================  智慧护栏界面 ==================================
  // 获取全部设备列表
  wgsGetDevList: (data) => post(HTTP + '/wgs/exec/wgsShow/getDevList', data),

  //==============================  延崇高速界面 ==================================
  // 获取初始化MQtt数据
  ycgsInit: (data) => post(HTTP + "/itgs_pro/init", data),

  getEnVTemp: (code) => get(HTTP + "/itgs/exec/screenYcgs/getEnVTemp/?devCodes=" + code), //温度折线图以及能见度折线图（改为MQTT）
  getMonitorCarFlow: (code) => get(HTTP + "/itgs/exec/screenYcgs/getMonitorCarFlow/?devCodes=" + code), //车流量柱形图（改为MQTT）
  getCount: (code) => get(HTTP + "/itgs/exec/screenYcgs/getCount/?devCodes=" + code), //获取车流量总数（改为MQTT）

  ycgsTransData: (devCode) => get(HTTP + "/itgsYcgs/transData/" + devCode), //根据设备编号获取对应的设备数据
  //获取设备图片数据
  itgsDevFile: (pageSize, pageNum, initSearch) => post(HTTP + '/itgs/itgsDevFile/page?pageSize=' + pageSize + '&pageNum=' + pageNum, initSearch),
  // getEnVTempLast: (code) => get(HTTP + "/itgs/exec/screenYcgs/getEnvLast/" + code), //初始化获取设备最后一条环境数据 -- 旧
  getEnVTempLast: (code) => get(HTTP + "/itgs/exec/screen/getEnvLast?" + code), //初始化获取设备最后一条环境数据

  // itgsDeviceBaseInfo: (data) => post(HTTP + "/itgs/deviceBaseInfo/query", data), // 根据项目编号获取对应的设备数据
  itgsMonitorCar: (pageSize, pageNum, initSearch) => post(HTTP + '/itgs/monitorCar/page?pageSize=' + pageSize + '&pageNum=' + pageNum, initSearch), //车流历史数据
  itgsAlarmDevFault: (pageSize, pageNum, initSearch) => post(HTTP + '/itgs/deviceAlarmInfo/page?pageSize=' + pageSize + '&pageNum=' + pageNum, initSearch), //设备故障告警数据
  //环境历史数据 
  itgsMonitorEnv: (pageSize, pageNum, initSearch) => post(HTTP + '/itgs/monitorEnv/page?pageSize=' + pageSize + '&pageNum=' + pageNum, initSearch),
  //气象设备的环境数据
  itgsMonitorEnvCode: (code) => get(HTTP + '/itgs/exec/monitorEnv/get/' + code),
  //节目单数据
  itgsProgram: (data) => post(HTTP + "/itgs/itgsProgram/query", data),
  // 设备位置
  devInfoPosition: (data) => post(`${HTTP}/itgs/exec/screen/getDevIndedx`, data),
  // 获取上行下行设备列表
  sxAndxxDevPosition: (data) => get(`${HTTP}/itgs/exec/screen/getCarDevices?projectCode=${data}`),

  //  通过设备编号获取设备状态
  itgsGetDevStatus: (data) => post(HTTP + "/itgs/exec/deviceBaseInfo/getDevStatus", data),


  // 2023-2-10 测试修复bug --------------------------------------------------------------------------------------start

  // 初始化环境温度折线图之前一小时
  initEchartDataByEnv: (data) => get(`${HTTP}/itgs/exec/screen/getEnV?projectCode=${data}`),
  // 初始化浓见度折线图之前一小时
  initEchartDataByVisi: (data) => get(`${HTTP}/itgs/exec/screen/getVisi?projectCode=${data}`),
  // 初始化获取车流量图表数据
  initEchartDataByCarFlow: (data) => get(`${HTTP}/itgs/exec/screen/getMonitorCarFlow?projectCode=${data}`),
  // 初始化获取车流量平均速度数据
  initEchartDataByCarSpeed: (data) => get(`${HTTP}/itgs/exec/screen/getMonitorCarFlowAvg?projectCode=${data}`),
  // 初始化告警数量
  initEchartDataByAlarm: (data) => get(`${HTTP}/itgs/exec/screen/getMonitorAlarm?projectCode=${data}`),

  // 相机推流是否开启
  cameraStreamStatus:(data) => post(`${HTTP}/itgs/exec/control/videoStart`,data),

  // -------------------------------------------------------------------------------------------------------------end

  //==============================  地埋式车检器界面 ==================================
  queryDevParkChangeLatestData: (data) => post(HTTP + "/geom/exec/tdGeomDevParkChange/queryDevParkChangeLatestData", data), //地磁实时车位状态数据
  tdGeomDevParkChangePage: (pageSize, pageNum, data) => post(HTTP + "/geom/tdGeomDevParkChange/page?pageSize=" + pageSize + "&pageNum=" + pageNum, data), //车位变化
  queryParkDuration: (data) => post(HTTP + "/geom/exec/tdGeomDevBeat/queryParkDuration", data), //今日停车时长

  //==============================  隧道巡检机器人即米诺机器人展示界面 ==================================
  // 设备实时数据列表
  devList: (param) => post(HTTP + "/robot/exec/reDataMonitorDevice/listByTunnelCode", param),
  // 新增运动控制
  controlSport: (param) => post(HTTP + "/robot/exec/reControlDevSport/sport", param),
  // 新增状态灯控制
  controlLight: (param) => post(HTTP + "/robot/exec/reControlDevLight", param),
  // 新增外设控制
  controlPerip: (param) => post(HTTP + "/robot/exec/reControlDevPerip", param),
  // 获取最新设备上下线数据
  newDevParamData: (param) =>
    post(HTTP + "/robot/exec/reControlDevParam/getNewDevParamData", param),
  // 新增参数单条数据
  devParam: (param) => post(HTTP + "/robot/exec/reControlDevParam", param),
  // 获取相机
  tunnelCamera: (param) => post(HTTP + "/robot/exec/reCamera/list", param),
  // 控制球机
  tunnelCameraControl: (param) => post(HTTP + "/robot/exec/reCamera/ptz", param),
  // 查询图表
  getEcharts: (data) => post(HTTP + "/robot/exec/tunnelAirData/charts", data),
};
export default axios.API;