import mqtt from 'mqtt';
import config from "../public/config";

let options = {
    username: "sudp",
    password: "sudp@ahad",
    cleanSession: false,
    keepAlive: 60000,
    clientId: 'sudp_' + Math.random().toString(16).substr(2, 8),
    connectTimeout: 4000
}
let mqtt_client = mqtt.connect(config.mqttUrl, options);
export default mqtt_client;
