<template>
  <router-view></router-view>
</template>

<script>
import { provide } from 'vue';
import newApi from 'sudp-common/utils/newApi';

export default {
  name: 'App',
  setup () {
    provide('newApi', newApi);
  }
}
</script>

<style lang="less">
@import "../node_modules/sudp-common/assets/style/index.less";
</style>
