import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn'; //中文
import 'element-plus/lib/theme-chalk/index.css';
import {
  createApp
} from 'vue'
import App from './App'
import Router from './router'
import 'sudp-common/utils/rem'
import mqtt_client from "sudp-common/utils/mqttUtils";

const app = createApp(App).use(ElementPlus, {
  locale
}).use(Router)

app.config.globalProperties.$mqtt = mqtt_client;

app.mount('#app')